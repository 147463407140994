.services {
    padding: 2rem 0rem;
    background-color: #87878721;
    width: 100%;
}

.service-box {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.service-box h2 {
    text-align: center;
    color: #000000;
    font-size: 42px;
    font-weight: 300;
    position: relative;
    z-index: 2;
    padding-bottom: 23px;
    font-weight: bold;
}

.service-box-main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.services-cards {
    padding: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 18px 0px rgba(194, 200, 213, 0.3);
    border-radius: 7px;
}

.services-cards-image {
    position: relative;
    margin-bottom: 10px;
    overflow: hidden;
}

.services-cards-image img {
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    height: 180px;
    transform: scale(1);
    transition: all 0.3s ease-in-out;
}

.services-cards-image img:hover {
    transform: scale(1.05);
    border-radius: 7px;
}

.services-cards-title h5 {
    color: black;
    padding: 5px;
    margin: 0;
    font-size: 16px;
}

@media (min-width: 1200px) {
    .service-box {
        max-width: 1140px;
    }
}


@media only screen and (max-width: 990px) {
    .service-box-main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }

    .service-box {
        max-width: 720px;
    }
}

@media only screen and (max-width: 680px) {
    .service-box-main {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .about-left .about-right {
        width: 100%;
    }

    .service-box {
        max-width: 520px;
    }
}
@media only screen and (max-width: 420px) {
    .service-box-main {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }

}